var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticStyle: {},
    attrs: {
      headers: _vm.headers,
      items: _vm.helpDesks.items,
      options: _vm.options,
      "server-items-length": _vm.helpDesks.count,
      loading: _vm.loading,
      "no-data-text": _vm.$t("messages.emptyState", {
        entity: _vm.$tc("models.helpDesk.entity")
      }),
      "no-results-text": _vm.$t("messages.emptyState", {
        entity: _vm.$tc("models.helpDesk.entity")
      }),
      flat: ""
    },
    on: {
      "update:options": [
        function($event) {
          _vm.options = $event
        },
        _vm.updateOptions
      ]
    },
    scopedSlots: _vm._u(
      [
        {
          key: "item.actions",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.editHelpDesk(item)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-pencil")])],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.deleteHelpDesk(item)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-delete")])],
                1
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }